<template>
<div class="PosterHome">
    <div class="TitleDiv">
      <h1>{{ Title }}</h1>
    </div>
    <div class="imgDiv"></div>
  </div>
</template>

<script>

export default {
  name: 'poster',
  props:['Title','ImgUrl']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ====== PosterHome ========= */

.PosterHome{
  position: relative;
  border: 1px solid none;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 400px;
  background:#702c29;
  color: white;
  text-shadow: 4px 4px 6px rgb(0, 0, 0);
  direction: rtl;
  overflow: hidden;
}

.TitleDiv{
  flex-grow: 1;
  text-align: center;
  align-self: center;
}

.imgDiv{
  background-image: linear-gradient(to right,#0000 ,#000000af),
  url('../assets/photo/photo8.jpg');
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  width: 100%;
  max-width: 950px;
  height: 100%;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 100% 100%, 0 100%);
}

.PosterHome .imgDiv img{
  width: 200px;
}


@media (max-width: 800px) {
  .PosterHome .imgDiv{
    display: none;
  }
  .PosterHome{
    background-image: linear-gradient(to bottom,#0000 ,#000000da),
    url('../assets/photo/photo8.jpg'); 
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    text-align: center;
    padding: 0px 15px;
  }
}
</style>
