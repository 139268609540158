import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/view/Home.vue';
import Menu from '@/view/Menu.vue';
import Cart from '@/view/Cart.vue';



const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta:{
			title:"עמוד הבית",
		},
	},
	{
		path: '/gallery',
		name: 'gallery',
		component: Home,
		meta:{
			title:"gallery",
		},
	},
	{
		path: '/contact',
		name: 'contact',
		component: Home,
		meta:{
			title:"contact",
		},
	},
	{
		path: '/menu',
		name: 'menu',
		component: Menu,
		meta:{
			title:"תפריט",
		},
	},
	{
		path: '/cart',
		name: 'cart',
		component: Cart,
		meta:{
			title:"סל קניות",
		},
	},
	// {
	// 	path: `/search=":Input"`,
	// 	// path: `/search`,
	// 	name: 'SearchTemplate',
	// 	component: SearchTemplate,
	// 	meta:{
	// 		title:"חיפוש",
	// 	},
	// },
	
];

let previousRoute = null;

const router = createRouter({
	history: createWebHistory(),
	routes,
	
});



router.beforeEach((to, from, next)=>{
	document.title = `${to.meta.title}`;
	previousRoute = from; // Store previous route
	next();
});



export { router, previousRoute };
export default router;


