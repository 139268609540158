<template>
    <div class="content" @click="openStory">
      <div class="img-container">
        <img
        :src="require(`@/assets/Story/photo/${StoryImg}`)"
        class="card-img-top"
        />
      </div>
      <div class="text-container">
        {{ titleStory }}
      </div>
  </div>
  
</template>

<script>

export default {
  name: 'StoryCircel',
  props:['StoryImg','titleStory','idStory'],
  mounted(){

  },
  methods:{
    openStory(){
      this.$emit('StoryOpen',this.idStory)
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

*{
  direction: rtl;
  box-sizing: border-box;
}

body{
  min-height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: white;
  place-items: center;
  color: rgb(51, 51, 51);
}

.content{
  display: grid;
  grid-gap: 0.5rem;
  margin: 0;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in;
}

.content:hover{
  transform: scale(1.1);
}

.img-container{
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: 
  radial-gradient(rgba(0,0,0,0.15) 60%, transparent 0),
  radial-gradient(white 65%, transparent 0),
  linear-gradient(to top right, orange, deeppink)
  ;
}

.img-container img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: circle(42%);
}

.content:last-child::after,.content:first-child::before{
  content: '';
  position: absolute;
  width: 8rem;
  height: 100%;
  right: -2rem;
}


.text-container{
  border: 1px solid none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  direction: rtl;
}




</style>
