<template>
<Navbar :quantityItems="products.length" />
<posterPic :PicName="poster"/>
<div class="cartContainer">
  <table>
    <thead>
      <tr>
        <th>מוצר</th>
        <th>מחיר</th>
        <th>כמות</th>
        <th>סך הכל</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="product in products" :key="product">
        <td class="imgTd"><img :src="require(`@/assets/photo/${product.productImg}`)"></td>
        <td>{{ product.productPrice }}<span>&#8362;</span></td>
        <td>
          <i class="bi bi-dash-circle" @click="this.minusQuantity(product.name)" @mouseenter="addClass" @mouseleave="removeClass"></i>
          <span>{{ product.productQuantity }}</span>
          <i class="bi bi-plus-circle"  @click="this.addQuantity(product.name)" @mouseenter="addClass" @mouseleave="removeClass"></i>
        </td>
        <td>{{ product.productPrice * product.productQuantity}}<span>&#8362;</span></td>   
      </tr>
    </tbody>
  </table>
  <div class="paymentDiv">
    <button class="paymentButton">לתשלום</button>
    
  </div>

</div>

<div class="empty">
</div>
  
<Footer/>
</template>


<script>
import Navbar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import posterPic from '@/components/posterPic.vue'
import CakeContainer from '@/components/CakeContainer.vue'
import NavMenu from '@/components/navMenu.vue'


export default {
  name: 'Cart',
  components: {
    Navbar,
    Footer,
    posterPic,
    CakeContainer,
    NavMenu
  },
  props:["productImg","productPrice","productQuantity","productCountPrice"],
  data(){
    return{
      poster:"bgcoverMenu.jpg",
      products:[
      {
        productImg:"photo1.jpg",productPrice:"300",productQuantity:"3",name:'test1'
      },
      {
        productImg:"photo2.jpg",productPrice:"200",productQuantity:"2",name:'test2'
      },
      {
        productImg:"photo3.jpg",productPrice:"300",productQuantity:"1",name:'test3'
      },
      {
        productImg:"photo4.jpg",productPrice:"200",productQuantity:"2",name:'test4'
      }  
      ],
    }
  },
  methods: {
    addClass: function (e) {
      if (e.target.classList.contains("bi-dash-circle")) {
        e.target.classList.replace("bi-dash-circle","bi-dash-circle-fill");
      }else if (e.target.classList.contains("bi-plus-circle")) {
        e.target.classList.replace("bi-plus-circle","bi-plus-circle-fill");
      }
    },
    removeClass: function (e) {
      if (e.target.classList.contains("bi-dash-circle-fill")) {
        e.target.classList.replace("bi-dash-circle-fill","bi-dash-circle");
      }else if (e.target.classList.contains("bi-plus-circle-fill")) {
        e.target.classList.replace("bi-plus-circle-fill","bi-plus-circle");
      }
    },
    addQuantity(res){
      let vm = this.products
      for (let i=0; i < vm.length; i++){
        if(vm[i].name == res){
          let some = parseInt(vm[i].productQuantity)
          let clae =  some + 1
          // console.log(clae)
          vm[i].productQuantity = clae
        }
      }
    },
    minusQuantity(res){
      let vm = this.products
      for (let i=0; i < vm.length; i++){
        if(vm[i].name == res){
          if(vm[i].productQuantity == 1){
            break
          }
          let some = parseInt(vm[i].productQuantity)
          let clae =  some - 1
          // console.log(clae)
          vm[i].productQuantity = clae
        }
      }
    }
  },
  
}

</script>

<style scoped>

.cartContainer{
  position: relative;
  top: 120px;
  border: 1px solid none;
  width: 75%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.hovering{
  color: red
}

table,thead,tbody{
  direction: rtl;
  text-align: center;
  width: 100%;
}

thead, tbody, tfoot, tr, td, th {
  width: 10%;
}

thead tr{
  border-bottom: 4px solid pink;
}

tbody tr{
  position: relative;
  border-top: 2px solid pink;
  border-bottom: 2px solid pink;
}
tbody tr td{
  padding: 20px;
  font-size: 25px;
}

.imgTd img{
  height: 200px;
  height: 200px;
}

.paymentDiv{
  position: relative;
  top: 10px;
  border: 1px solid none;
  width: 30%;
  height: 150px;
}

.paymentButton{
  background-color: pink;
  border: none;
  width: 40%;
  padding:40px;
  transition: ease 0.4s;
  font-size: 24px;
}

.paymentButton:hover{
  background-color: rgb(231, 159, 171);
}

.empty{
  height: 100px;
}

span{
  padding: 0% 2% 0% 2%;
}

i{
  font-size: 22px;
  cursor: pointer;
/* add fill to button minus and plus */
}

i:hover{
  transition-delay: ease 0.9s;
}


@media (max-width: 830px) {
  .cartContainer{
    border: 1px solid red;
    width: 100%;
    overflow: hidden;
  }
}



</style>




