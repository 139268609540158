<template>
  <div id="myBtnContainer">
    <h2>סינון</h2>
    <hr>

    <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off">
    <label class="btn optionsbtn clickedNav" for="option1" @click="filterSelection('all');">הכל</label>

    <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
    <label class="btn optionsbtn" for="option2" @click="filterSelection('choclate');">שוקולד</label>

    <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off">
    <label class="btn optionsbtn" for="option3" @click=" filterSelection('vanila');">וניל</label>

    <input type="radio" class="btn-check" name="options" id="option4" autocomplete="off">
    <label class="btn optionsbtn" for="option4" @click=" filterSelection('birthday');">יום הולדת</label>

    <input type="radio" class="btn-check" name="options" id="option5" autocomplete="off">
    <label class="btn optionsbtn" for="option5" @click=" filterSelection('marocian');">עוגיות מרוקאיות</label>

    <input type="radio" class="btn-check" name="options" id="option6" autocomplete="off">
    <label class="btn optionsbtn" for="option6" @click=" filterSelection('shabbat');">עוגות לשבת</label>

  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    
  },
  mounted(){
    // addEventListener.this.filterSelection("all")
  },
  methods: {
    filterSelection(c) {
      const column = document.getElementsByClassName('column')
      const text = document.getElementsByClassName('disable-id-cake')
      const coverColor = document.getElementsByTagName('label')

        
          if( c == "all" ){
            this.w3AddClass(coverColor[0],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[0],"clickedNav")
          }

          if( c == "choclate" ){
            this.w3AddClass(coverColor[1],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[1],"clickedNav")
          }

          if( c == "vanila" ){
            this.w3AddClass(coverColor[2],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[2],"clickedNav")
          }

          if( c == "birthday" ){
            this.w3AddClass(coverColor[3],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[3],"clickedNav")
          }

          if( c == "marocian" ){
            this.w3AddClass(coverColor[4],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[4],"clickedNav")
          }

          if( c == "shabbat" ){
            this.w3AddClass(coverColor[5],"clickedNav")
          }else{
            this.w3RemoveClass(coverColor[5],"clickedNav")
          }
        
      
      

      for (let i = 0; i < text.length; i++) {

        if (text[i].textContent == "עוגת יום הולדת"){
          this.w3AddClass(column[i], "birthday")
        }else if (text[i].textContent == "עוגת שוקולד"){
          this.w3AddClass(column[i], "choclate")
        }else if (text[i].textContent == "עוגת וניל"){   
          this.w3AddClass(column[i], "vanila")
        }else if (text[i].textContent == "עוגיות מרוקאיות"){   
          this.w3AddClass(column[i], "marocian")
        }else if (text[i].textContent == "עוגות לשבת"){   
          this.w3AddClass(column[i], "shabbat")
        } 
      }

      var x, i;
      x = document.getElementsByClassName("column");
      if (c == "all") c = "";
      for (i = 0; i < x.length; i++) {
        this.w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(c) > -1) this.w3AddClass(x[i], "show");
      }
    },
    w3AddClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
      }
    },
    w3RemoveClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
          arr1.splice(arr1.indexOf(arr2[i]), 1);     
        }
      }
      element.className = arr1.join(" ");
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

*{
padding : 0;
margin: 0; 
}

/* Style the buttons */
.btn {
  border-bottom: 1px solid pink;
  outline: none;
  padding: 15px 16px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 10px;
  width: 100%;
  transition: 0.2s ease-out;
}

.btn:hover{
  background-color: rgb(208, 158, 167);
  color: white;
  transition: 0.2s ease-in;
}

#myBtnContainer{
  position: relative;
  border: 1px solid none;
  width: 100%;
  margin-top: 10%;
  text-align: center;
  z-index: 100000;
}

#myBtnContainer h2{
  text-align: center;
  color: #666;
}

hr{
  position: relative;
  top: 10px;
  padding: 8px;
}

.clickedNav{
  border:1px solid none !important;
  background-color: pink !important;
  color: white !important;
}


@media (max-width: 864px) {
  
  #myBtnContainer{ 
    border: 1px solid none;
    position: relative;
    padding-left: 5%;
    width: 100%;
    direction: rtl;
  }

  .btn,.btn-check {
    width: 22%;
    font-size: 100%;
    margin-right: 2%;
  }

  #myBtnContainer h2{
  text-align: center;
  color: #666;
  }

  hr{
    position: relative;
    width: 97%;
    padding: 8px;
  }

  
}

@media (max-width: 469px) {
  
  #myBtnContainer{ 
    width: 100%;
    right: 0;
    padding-right: 5%;
  }

  .btn,.btn-check {
    width: 23%;
    font-size: 70%;
    margin-right: 1%;
  }

  
}


</style>
