<template>
    <!-- <div class="column show">
      <div class="content">
        <img :src="require(`@/assets/photo/photo1.jpg`)"
        alt="cake" 
          >
        <div class="content-title">
          <h4>fdsfsdf</h4>
          <hr>
          <p>
          fsdfsdfdf dsfsd fsdfs fsfsd         
          </p>
          <h5><span>200</span><span>&#8362;</span></h5>
          <h6>תגים: <span class="tagsName"><a href="search">fdsfs</a></span></h6>
          <span class="disable-id-cake">fdsfs</span>
          <div class="AddButtonDiv"><button @click="addToCart(id)" class="addCartProduct btn btn-primary">הוספה לסל <i class="bi bi-cart4"></i></button></div>
        </div>      
      </div>
    </div>   -->
    <div class="column show" v-for="skeletonContainer in skeletonTest">
      <div class="content">
        <div class="img-it skeleton"></div>
        <div class="content-title">
          <div class="skeleton skeleton-text"></div>
          <hr class="skeleton">
          <p>
            <div class="skeleton skeleton-text"></div>   
            <div class="skeleton skeleton-text"></div>      
            <div class="skeleton skeleton-text"></div>
          </p>
           
          <h5><span> <div class="skeleton skeleton-text"></div></span></h5>
          <h6> <span class="tagsName"> <div class="skeleton skeleton-text"></div></span></h6>
          <span class="disable-id-cake"> <div class="skeleton skeleton-text"></div></span>
          <div class="AddButtonDiv"><button class="btn skeleton skeletonBtn "></button></div>
        </div>      
      </div>
    </div>  
</template>

<script>

export default {
  name: 'CakeContainer',
  mounted() {
  },
  data(){
    return{
      skeletonTest:
      [
      {
        id:1
      },
      {
        id:2
      },
      {
        id:3
      },
      {
        id:1
      },
      {
        id:2
      },
      {
        id:3
      },
      ]
    }
  }
  
   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

* {
  box-sizing: border-box;
  direction: rtl;
}

.disable-id-cake{
  display: none;
}

.img-it{
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Center website */

h1 {
  font-size: 50px;
  word-break: break-all;
}

/* Add padding BETWEEN each column (if you want) */

/* Create three equal columns that floats next to each other */
.column {
  border:1px solid none;
  border-radius: 1rem;
  float: right;
  /* margin-left: 2%; */
  /* height: 560px; */
  /* width: 390px; */
  display: none; /* Hide columns by default */
  /* margin-top: 3%; */
  transition: 0.2s ease-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


/* .column:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-2%);
  transition: 0.2s ease-in;
} */

/* Content */
.content {
  border: 1px solid none;
  border-radius: 25px 25px 25px 25px;
  background-color: white;
  width: 100% !important;
  text-align: center;
  
}

.content-title{
  border: 1px solid none;
  padding: 4% 4% 3% 4% ;
  padding-top: 15%;

}

.content-title hr{
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  padding: 5px;
}

.content-title p{
  border: 1px solid none;
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  color: black;
}

/* The "show" class is added to the filtered elements */
.show {
  display: block;
}


#myBtnContainer{
  margin-top: 10%;
}



h4,h5,h6{
  direction: rtl;
  text-align: center !important;
  color: black;
  padding: 10px;
}

.tagsName:hover{
  color: rgb(166, 0, 255);
}

.AddButtonDiv{
  width: auto;
  height: auto;
  padding: 15px;
}

.skeletonBtn{
  border:1px solid none;
  padding: 10px;
}


  /* -------------------   skeleton ---------------- */

  .skeleton{
    animation: skeleton-loading 2s linear infinite alternate;
  }
  .skeleton-text{
    width: 100%;
    height: .5rem;
    margin-bottom: .25rem;
    border-radius: .125rem ;
  }

  @keyframes skeleton-loading{
    0%{
      background-color: hsl(200 ,20% , 70%)
    }
    100%{
      background-color: hsl(200,20%,95%);
    }
  }



@media (max-width: 830px) {
  
  .column {
    width: 90%;
    margin-top: 8%;
    margin-right: 3%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
  }

  h4{
    font-size: 13px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 12px;
  }

 

  
}


</style>
